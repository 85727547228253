import React, { useCallback } from "react";
import { TouchableOpacity } from "react-native";
import { Flex, Icon, View, Text, Hidden, Center } from "native-base";
import { RFValue } from "react-native-responsive-fontsize";
import { cloneDeep } from "@apollo/client/utilities";
import { MaterialCommunityIcons } from "@expo/vector-icons";

import { Card } from "../../Card";
import { useProductTabNavigation } from "../../ProductsTabView";
import { useNavigation } from "@react-navigation/native";

import { width } from "@components/Dimension";
import DefaultSourceImg from '@assets/images/combo_not_found.png';

import { styles } from "./styles";
import { Config } from "@configs/Config";
import { useAuth } from "../../../contexts/Auth";
import { DateConvert } from "../../../helpers/Date";
import { CommercialPolicyComboType } from "@types/CommercialPolicyComboType";


export type ComboCardProps = {
  commercialPolicy: CommercialPolicyComboType
}

export function ComboCard(props: ComboCardProps) {
  const {
    tabNavigation,
    setTabParams,
    setTabNavigation,
    getNavigationChildren,
    setTabNavigationLoading,
  } = useProductTabNavigation();

  const navigation = useNavigation()

  const onNavigate = useCallback(() => {

    if (!setTabParams) {
      navigation.navigate('Combo', {
        commercialPolicyId: commercialPolicy.id,
      })
      return;
    }

    setTabParams({
      name: commercialPolicy.name,
      commercialPolicyId: commercialPolicy.id,
      requiredBasketAmount: commercialPolicy.numberItemsRequiredBasket,
    });
    const newTabNavigation = cloneDeep(tabNavigation);
    const index = newTabNavigation.index;
    const route = getNavigationChildren("comboProducts", index);
    if (route?.length) {
      setTabNavigationLoading(true);
      newTabNavigation.routes[index] = route[0];
      setTabNavigation({ ...newTabNavigation, index });
    }
  }, [tabNavigation, getNavigationChildren])

  const { user } = useAuth();

  const { commercialPolicy } = props;

  const getcommercialPolicyUrl = (commercialPolicyId: number) => {
    const industryName = user?.getNameIndustryStore() ?? "";
    return `${Config.URL_CDN_BASE
      }/${industryName}/commercialPolicy/${commercialPolicyId}.png`;
  };

  return (
    <TouchableOpacity
      onPress={onNavigate}
    >
      <Card.Container centerStyle={styles.center}>
        <Hidden only={['lg', 'xl', 'md', '2xl', '3xl', '4xl']}>
          <Center
            bg='orange.500'
            borderRadius={'md'}
            zIndex={2}
            position='absolute'
            bottom={"40"}
            right={"3"}
            px={[2, 3]}
            py={1.5}
          >
            <Text
              color='muted.100'
              fontSize={['xs']}
              fontWeight='700'
            >
              Até {commercialPolicy.maxDiscount}%
            </Text>
          </Center>
        </Hidden>
        <Card.Image
          h={[26, 48]}
          p={[0, 2]}
          source={getcommercialPolicyUrl(commercialPolicy.id)}
          alt={"Imagem " + commercialPolicy.name}
          defaultSourceImg={DefaultSourceImg}
        />
        <Card.Body>
          <Text
            fontWeight="600"
            marginTop={[0, 8]}
            noOfLines={1}
            lineHeight={"md"}
            fontSize={["xs", "md"]}
            color={"muted.800"}
            _dark={{
              color: "muted.400"
            }}
            fontStyle={'normal'}
            selectable
          >
            {commercialPolicy.name}
          </Text>
          <Hidden only={['base', 'sm']}>
            <>
              <View marginTop={4}>
                <Text style={styles.brandSubText}>
                  Valide as quantidades mínimas de cada produto
                </Text>
              </View>
              <Flex direction="row">
                <View style={styles.labelsContainer}>
                  <View style={styles.expirationLabelsContainer}>
                    <Icon
                      as={MaterialCommunityIcons}
                      name="clock-outline"
                      size={RFValue(2)}
                      style={{
                        marginTop: 3,
                        marginRight: 5,
                      }}
                    />
                    <Text style={styles.expirationDescription}>
                      até{" "}
                      {new DateConvert(
                        commercialPolicy.endValidityDate
                      ).getExpirationDate()}
                    </Text>
                  </View>
                </View>
                <View style={styles.discountLabelContainer}>
                  <Text style={styles.discountLabel}>
                    Até {commercialPolicy.maxDiscount}% OFF.
                  </Text>
                </View>
              </Flex>
            </>
          </Hidden>
          <Hidden only={['lg', 'xl', 'md', '2xl', '3xl', '4xl']}>
            <View mt={4}>
              <Text
                fontSize={'sm'}
                color={'muted.700'}
                lineHeight={'sm'}
                _dark={{
                  color: "muted.400"
                }}
              >
                Até {commercialPolicy.maxDiscount}% OFF.
              </Text>
              <View justifyContent={"flex-start"} flexDir={'row'} alignItems={"center"}>
                <Icon
                  as={MaterialCommunityIcons}
                  name="clock-outline"
                  size={3}
                  color={'muted.700'}
                />
                <Text
                  fontSize={'sm'}
                  ml={1}
                  color={'muted.700'}
                  lineHeight={'sm'}
                  _dark={{
                    color: "muted.400"
                  }}
                >
                  até {new DateConvert(commercialPolicy.endValidityDate).getExpirationDate()}
                </Text>
              </View>
            </View>
          </Hidden>
        </Card.Body>
      </Card.Container>
    </TouchableOpacity>
  );
}
