import React, { memo, useMemo, useState } from 'react';
import { Center, Image, IImageProps, Text } from 'native-base';

import DefaultSourceImg from '@assets/images/product_not_found.png';
import { useAuth } from '@contexts/Auth';
import { Config } from '@configs/Config';
import { ItemType } from '@types/CartType';

type ProductSummaryImageProps = {
  item: ItemType;
  _image?: IImageProps;
}

export const ProductSummaryImage = memo(({ item, _image }: ProductSummaryImageProps) => {
  const [isImageNotFound, setIsImageNotFound] = useState(false);
  const isNotFound = useMemo(() => isImageNotFound, [isImageNotFound]);
  const { user } = useAuth();

  const nameIndustry = user?.getNameIndustryStore();
  const url = nameIndustry
    ? Config.URL_CDN_BASE +
    `/${nameIndustry}/${item.code}.jpg`
    : "";


  return (
    <>
      <Image
        key={isNotFound ?
          `${item.code}:image-not-found` :
          item.code
        }
        source={isNotFound ? DefaultSourceImg : { uri: url ?? '' }}
        alt={item.product.description}
        resizeMode='contain'
        resizeMethod='scale'
        onError={() => {
          setIsImageNotFound(true)
        }}
        testID={
          isNotFound ?
            `${item.code}:image-not-found` :
            item.code
        }
        h={[24, 26, 32, 40]}
        {..._image}
      />

      {item.totalDiscount > 0 &&
        <Center
          borderRadius={4}
          bg='orange.500'
          _dark={{
            bg: 'orange.500'
          }}
          position='absolute'
          top={2}
          right={2}
          p={1}
        >
          <Text
            fontSize={['2xs', 'xs']}
            fontWeight='700'
            color='muted.100'
          >
            {item?.totalDiscount}% OFF
          </Text>
        </Center>
      }
    </>
  );
});