import { Config } from "@configs/Config";

const Cdn  = {
  getUrl(...args: any[]): string {
   return Config.URL_CDN_BASE + '/' + args.join('/');
  },
  getProductImageUrl(ean: string, nameIndustry: string): string {
    return nameIndustry ? Config.URL_CDN_BASE + `/${nameIndustry}/${ean}.jpg` : "";
  }
}


export default Cdn 