import React from "react";
import { useAuth } from "@contexts/Auth"
import { IndustryCard } from "../IndustryCard";
import { RetailterCard } from "../RetailterCard";
import { useCustomer } from "@contexts/CustomerContext";
import { CustomerCard } from "../CustomerCard";

type AlterCardPropos = {
  navigation: any
  industryName: string | undefined
}

export const AlterCard = ({ navigation, industryName }: AlterCardPropos) => {
  const { user } = useAuth();
  const { customer } = useCustomer();
  const isIndustryEmployee = user.isIndustryEmployee();

  if (customer && isIndustryEmployee) {
    return (
      <CustomerCard
        code={customer.code}
        businessName={customer.businessName || ""}
        onPress={() => navigation.navigate("ChooseCustomer")}
      />
    )
  }

  if (user.isPointOfSales()) {
    return <IndustryCard
      name={industryName || ''}
      onPress={() => navigation.navigate("ChooseIndustry")}
    />
  }

  if (user.isRetailer() || user.isTradeManager()) {
    return <RetailterCard />
  }

  return <></>
}