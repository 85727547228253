import React, { createContext, useState, useEffect, useContext, useMemo, ReactNode } from "react";
import { Platform } from "react-native";
import jwtDecode from "jwt-decode";
import * as WebBrowser from "expo-web-browser";
import { useAuthRequest } from "expo-auth-session";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { IndustryDataType } from "../types/IndustryDataType";
import { ApolloProvider } from "@apollo/client";
import { Config } from "@configs/Config";
import Storage from "../components/StorageTTL";
import { SsoProfileEnum } from "../enums/SsoProfileEnum";
import { AssortmentType } from "../types/AssortmentType";
import { AvailabilityConsolidateEnum } from "../enums/AvailabilityConsolidateEnum";
import { gatewayClient } from "@configs/gatewayClient";
import TagManager from "react-gtm-module";
import CryptoJS from 'crypto-js';

WebBrowser.maybeCompleteAuthSession();

const authorizationEndpoint = Config.SSO_URL + "/oauth2/authorize";
const tokenEndpoint = Config.SSO_URL + "/oauth2/access_token";
const redirectUriRecovery = Config.SSO_URL + "/recovery";

const oAuthScopes = Config.SSO_OAUTH_SCOPES.split(",");
const redirectUri = Config.SSO_REDIRECT_URI;

const keyToken = "@RNAuth:token";
const keyUser = "@RNAuth:user";
const discovery = {
  authorizationEndpoint,
  tokenEndpoint,
};

if (Config.GOOGLE_TAG_MANAGER_CODE != "" && Platform.OS === 'web') {
  TagManager.initialize({
    gtmId: Config.GOOGLE_TAG_MANAGER_CODE || ''
  })
}
interface UserData {
  token: string | null;
  userId: string | null;
  customer: string | null;
}
interface AuthContextDataProps {
  signed: boolean;
  user: User;
  loading: boolean;
  signIn(): Promise<void>;
  signOut(): Promise<void>;
  setUserData(user: User): Promise<void>;
  recovery(): string;
  setDataStore(item: IndustryDataType, user: User): Promise<void>;
  setAssortments(assortments: AssortmentType[], user: User): Promise<void>;
  getAuthToken(code: string): void;
  loginOut: boolean;
}

interface AuthContextProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthContextDataProps>(
  {} as AuthContextDataProps
);

export const AuthProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>(User.createEmpty());
  const [loading, setLoading] = useState(true);
  const [loginOut, setLoginOut] = useState(false);
  const secretKey = Config.SECRET_KEY;

  useEffect(() => {    
    async function loadStorageData() {     
      const storagedUser = await Storage.getItem(keyUser);
      const storagedToken = await Storage.getItem(keyToken);
      
      if (storagedUser && storagedToken) {
        let user = new User(storagedUser);
        user.token = storagedToken;
        user.setUserStorage(storagedUser);
        setUser(user);       
      }           
      setLoading(false);      
    }    

    const queryString = window.location.search;

    if(queryString.includes('funcionalmais')) {
      
      const queryWithoutQuestionMark = queryString.replace("?funcionalmais=", "");
      
      try {
        const decryptData = (ciphertext: string, key: string): UserData => {
          const bytes = CryptoJS.AES.decrypt(ciphertext, key);
          const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
          return JSON.parse(decryptedText) as UserData;
        };

        const dataDecoded = decryptData(queryWithoutQuestionMark, secretKey);

        if (dataDecoded.token && dataDecoded.userId && dataDecoded.customer) {
          const token = JSON.parse(dataDecoded.token);
          let userId = JSON.parse(dataDecoded.userId);
          let customer = JSON.parse(dataDecoded.customer);
          userId = JSON.parse(userId.value)          
         
          const decodedToken = jwtDecode(token.value) as Payload
         console.log("customer ", customer)
          Storage.setItem(
            keyToken,
            token.value,
            decodedToken.exp
          );
          Storage.setItem(keyUser, userId, userId.exp);
          Storage.setItem('Customer', customer.value, userId.exp);
        }        
             
      } catch (error) {
        console.error("Error parsing URL parameters:", error);
      }
    }    

    loadStorageData();
  }, []);


  
  const [request] = useAuthRequest(
    {
      clientId: Config.SSO_OAUTH_CLIENT_ID,
      scopes: oAuthScopes,
      usePKCE: true,
      redirectUri,
    },
    discovery
  );

  async function signIn() {
    if (request?.url) {
      const url = new URL(request.url);
      localStorage.setItem('code_verifier', request?.codeVerifier ?? '');
      window.open(url, '_self');
    }
  }

  async function getAuthToken(code: string) {
    try {
      const response = await fetch(tokenEndpoint, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          code: code,
          redirect_uri: redirectUri,
          client_id: Config.SSO_OAUTH_CLIENT_ID,
          grant_type: "authorization_code",
          code_verifier: localStorage.getItem('code_verifier'),
        }),
      });

      localStorage.removeItem('code_verifier');
      const infoSSO = (await response.json()) as ResponseSSO;
      const payload = jwtDecode(infoSSO?.access_token) as Payload;
      const userJwt = new User(payload, infoSSO?.access_token);

      if (!userJwt.hasAccess()) {
        throw new Error("invalid_profile");
      }

      await Storage.setItem(
        "@RNAuth:token",
        JSON.stringify(infoSSO?.access_token),
        infoSSO?.expires_in
      );

      await saveUser(userJwt);
    } catch (error: any) {
      throw error;
    }
  }

  async function setUserData(user: User): Promise<void> {
    await Storage.setItem(
      "@RNAuth:token",
      JSON.stringify(user.token),
      user.exp
    );

    await saveUser(user);
  }

  async function signOut() {
    setLoginOut(true);
    await AsyncStorage.removeItem(keyToken);
    await AsyncStorage.removeItem(keyUser);
    setUser(User.createEmpty());
  }

  function recovery(): string {
    return redirectUriRecovery;
  }

  async function saveUserStore(userJwt: User) {
    let expires_in = new Date().getTime();
    await Storage.getItemExpiration("@RNAuth:token").then((data: any) => {
      const timeStamp = new Date(data.toLocaleString());
      expires_in = (timeStamp.getTime() - new Date().getTime()) / 1000;
    });
    await Storage.setItem("@RNAuth:user", JSON.stringify(userJwt), expires_in);
  }

  async function saveUser(userInput: User): Promise<User> {
    setUser(userInput);
    await saveUserStore(userInput);
    return userInput;
  }

  async function setDataStore(item: IndustryDataType, userInput: User) {
    userInput.setIndustryStore(item.industry);
    userInput.setCommercialPolicyIdStore(item.id ?? 0);
    userInput.setNameIndustryStore(item.nameIndustry);
    await saveUser(userInput);
  }

  async function setAssortments(assortments: AssortmentType[], userInput: User) {
    userInput.setAssortments(assortments);
    await saveUser(userInput);
  }

  const value = useMemo(
    () => ({
      signed: !!user.isEmpty(),
      user,
      loading,
      signIn,
      signOut,
      setDataStore,
      setAssortments,
      recovery,
      setUserData,
      getAuthToken,
      loginOut,
    }),
    [loading, user],
  )

  return (
    <AuthContext.Provider
      value={value}
    >
      <ApolloProvider client={gatewayClient(user?.token)}>
        {children}
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export class User {
  private name: string;
  private profiles: { [key: string]: string[] } = {};
  private industryStore: string = '';
  private identity: string;
  private commercialPolicyIdStore: number = 0;
  private nameIndustryStore: string = '';
  private uuid: string;
  public exp: number;
  public token;
  private assortments: AssortmentType[] = [];

  constructor(payload: Payload | UserStorage, token?: string) {
    this.profiles = payload?.profiles || {};
    this.name = payload.name;
    this.identity = payload?.identity;
    this.uuid = payload.uuid;
    this.exp = payload?.exp;
    this.token = token;
  }

  static createEmpty(): User {
    return new User({} as Payload);
  }

  static createByToken(token: string): User {
    return new User(jwtDecode(token), token);
  }

  setUserStorage(userStorage: UserStorage) {
    this.commercialPolicyIdStore = userStorage.commercialPolicyIdStore || 0;
    this.nameIndustryStore = userStorage.nameIndustryStore || "";
    this.industryStore = userStorage?.industryStore;
    this.setAssortments(userStorage?.assortments || []);
  }

  isEmpty = (): boolean => {
    return this.identity ? true : false;
  };

  getName = (): string => {
    return this.name;
  };

  hasSsoRole = (role: string) => {
    return !!this.profiles[role];
  };

  hasSsoProfile = (role: string, identity: string) => {
    return this.getSsoRoleIdentities(role).includes(identity);
  };

  getSsoRoles = () => {
    return Object.keys(this.profiles);
  };

  getSsoRoleIdentities = (role: string) => {
    return this.profiles[role] || [];
  };

  getAssortments = () => {
    return this.assortments;
  };

  hasAnyOfTheseSsoRoles = (roles: string[]) => {
    if (!roles || roles.length === 0) {
      throw Error("No roles informed");
    }

    return this.getSsoRoles().filter((role) => roles.includes(role)).length > 0;
  };

  hasAllOfTheseSsoRoles = (roles: string[]) => {
    if (!roles || roles.length === 0) {
      throw Error("No roles informed");
    }

    return (
      this.getSsoRoles().filter((role) => roles.includes(role)).length ===
      roles.length
    );
  };

  hasSelectedIndustry = () => {
    return this.industryStore || this.industryStore?.length > 0;
  };

  getIndustry = () => {
    return this.industryStore;
  };

  getUuid = () => {
    return this.uuid;
  };

  setAssortments = (assortments: AssortmentType[]) => {
    this.assortments = assortments;
  };

  setIndustryStore = (industry: string) => {
    this.industryStore = industry;
  };

  getSsoIdentity = () => {
    return this.identity;
  };

  setCommercialPolicyIdStore = (id: number) => {
    this.commercialPolicyIdStore = id;
  };

  getCommercialPolicyIdStore = () => {
    return this.commercialPolicyIdStore;
  };

  setNameIndustryStore = (name: string) => {
    this.nameIndustryStore = name;
  };

  getNameIndustryStore = () => {
    return this.nameIndustryStore;
  };

  getUserCode = () => {   
    if (this.hasSsoRole(SsoProfileEnum.INDUSTRY_EMPLOYEE)) {
      return this.getSsoRoleIdentities(SsoProfileEnum.INDUSTRY_EMPLOYEE)[0];
    }

    if (this.isPointOfSales()) {
      return this.getSsoRoleIdentities(SsoProfileEnum.POINT_OF_SALES_BUYER)[0];
    }

    if (this.isRetailer()) {
      return this.getSsoRoleIdentities(SsoProfileEnum.RETAILER)[0];
    }

    return null;
  };


  getProfile = () => {
    if (this.hasSsoRole(SsoProfileEnum.INDUSTRY_EMPLOYEE)) {
      return SsoProfileEnum.INDUSTRY_EMPLOYEE
    }

    if (this.isPointOfSales()) {
      return SsoProfileEnum.POINT_OF_SALES_BUYER;
    }

    if (this.isRetailer()) {
      return SsoProfileEnum.RETAILER;
    }
  };

  getAvailability = () => {
    if (this.hasSsoRole("industry_employee")) {
      return AvailabilityConsolidateEnum.INDUSTRY
    }

    return AvailabilityConsolidateEnum.CUSTOMER
  };

  getToken = () => {
    return this.token;
  }

  hasAccess = () => {
    return this.hasAnyOfTheseSsoRoles(Object.values(SsoProfileEnum));
  };

  isPointOfSales = () => {
    return this.hasSsoRole(SsoProfileEnum.POINT_OF_SALES_BUYER);
  };

  isIndustryEmployee = () => {
    return this.hasSsoRole(SsoProfileEnum.INDUSTRY_EMPLOYEE);
  };

  isRetailer = () => {
    return this.hasSsoRole(SsoProfileEnum.RETAILER);
  };

  isTradeManager = () => {
    return this.hasSsoRole(SsoProfileEnum.TRADE_MANAGER);
  };

  isUserAllowedToImpersonate = () => {
    return this.isIndustryEmployee() || this.isTradeManager();
  };
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

type AuthResponse = {
  params: {
    code: string;
  };
  type: string;
};

type ResponseSSO = {
  access_token: string;
  expires_in: number;
  opaque_token: string;
  refresh_token: string;
  token_type: string;
};

type Payload = {
  email: string;
  exp: number;
  iat: number;
  identity: string;
  iss: string;
  jti: string;
  name: string;
  profiles: { [key: string]: string[] };
  sub: string;
  uuid: string;
};

type UserStorage = {
  identity: string;
  name: string;
  profiles: { [key: string]: string[] };
  uuid: string;
  industryStore: string;
  nameIndustryStore: string;
  commercialPolicyIdStore: number;
  token: string;
  exp: number;
  assortments: AssortmentType[];
};
