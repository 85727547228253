import React, { useState } from 'react';
import { AspectRatio, Center, Image } from 'native-base';

import { LinkButton } from '@components/LinkButton';

import { useAuth } from '@contexts/Auth';
import { useCustomer } from '@contexts/CustomerContext';
import { IndustryDataType } from '@types/IndustryDataType';
import { getSessionStorage } from '@helpers/SessionStorage';
import { finishPromotionalActionSession } from '@components/PromotionalAction';

type Props = {
  industry: IndustryDataType;
}

export function IndustryButton({ industry }: Props) {
  const [isImageFound, setIsImageFound] = useState(true);
  const { setDataStore, user } = useAuth();
  const { saveCustomer } = useCustomer();
  const customerCode = user?.getUserCode() || "";

  const renderImage = (item: IndustryDataType) => {
    return (
      <Image
        source={{ uri: item.url }}
        defaultSource={{ uri: item.url }}
        alt={item.nameIndustry}
        resizeMode='contain'
        onError={() => {
          setIsImageFound(false);
        }}
      />
    );
  }

  const renderDefaultButton = (industryName: string) => {
    return (
      <Center
        bg='primary.500'
        rounded='md'
        _text={{
          fontSize: ['sm', 'md'],
          fontFamily: 'heading',
          color: 'white'
        }}
      >
        {industryName}
      </Center>
    );
  }


  const onTo = () => {
    if (user.isPointOfSales()) {
      return {
        screen: "PreferredWholesaler",
        params: { industry: industry.nameIndustry },
      }
    }

    if (user.isRetailer()) {
      return {
        screen: "ChooseCustomer",
        params: { industry: industry.nameIndustry },
      }
    }

    if (user.isTradeManager()) {
      return {
        screen: "ChooseCustomer",
        params: { industry: industry.nameIndustry },
      }
    }

    return {
      screen: "PreferredWholesaler",
      params: { industry: industry.nameIndustry },
    }
  }

  const disablePromotionalActionSession = async (industry: IndustryDataType) => {
    const promo = await getSessionStorage('promotionalAction');

    if (promo.nameIndustry !== industry.nameIndustry) {
      await finishPromotionalActionSession();
    }
  }

  return (
    <LinkButton
      onPressLink={async () => {
        await setDataStore(industry, user);
        await saveCustomer({ code: customerCode });
        await disablePromotionalActionSession(industry);
      }}
      to={onTo()}
      testID={`${industry.nameIndustry}-button`}
      w={40}
      h={12}
      mb={[12, 16]}
      mr={[0, 8, 8, 20, 32]}
    >
      <AspectRatio w='full' ratio={16 / 9}>
        {isImageFound ? renderImage(industry) : renderDefaultButton(industry.nameIndustry)}
      </AspectRatio>
    </LinkButton>
  );
}